const brandOptions = [
    'Toyota',
    'Honda',
    'Hyundai',
    'Nissan',
    'BMW',
    'Kia',
    'Volvo',
    'Mitsubishi',
    'Subaru',
    'Peugeot',
    'Suzuki',
    'Jaguar',
    'Porsche',
    'Mazda',
    'Mini',
    'Mercedes-Benz',
    'Audi',
    'Volkswagen',
    'Chevrolet',
    'Ford',
    'BYD',
    'Tesla',
    'Jeep',
    'Land Rover',
    'Lexus',
    'Renault',
    'Citroen',
    'Fiat',
    'Ford',
];

const categoryOptions = [
  { label: 'SUV', value: 'SUV' },
  { label: 'Luxo', value: 'Luxo' },
  { label: 'Esportivo', value: 'Esportivo' },
  { label: 'Sedan', value: 'Sedan' },
  { label: 'Hatch', value: 'Hatch' },
  { label: 'Compacto', value: 'Compacto' },
  { label: 'Eletrico', value: 'Eletrico' },
];

const fuelOptions = ['Flex', 'Gasolina', 'Diesel', 'Etanol', 'Elétrico'];

const transmissionOptions = ['Manual', 'Automático', 'CVT'];

const colors = {
  Preto: '#000000',
  Branco: '#FFFFFF',
  Prata: '#C0C0C0',
  Vermelho: '#FF0000',
  Azul_Escuro: '#00008B',
};

export { brandOptions, categoryOptions, fuelOptions, transmissionOptions, colors };

